import { TIME_ZONES } from '@drayalliance/types';
import moment from 'moment-timezone';

export default function dateTimeRangeDisplayFormatter(
  value: Record<'startValue' | 'endValue', string | null | undefined>,
  timezone?: TIME_ZONES
) {
  let momentStartValue: moment.Moment;
  let momentEndValue: moment.Moment;
  if (timezone) {
    momentStartValue = moment(value.startValue).tz(timezone);
    momentEndValue = moment(value.endValue).tz(timezone);
  } else {
    momentStartValue = moment(value.startValue);
    momentEndValue = moment(value.endValue);
  }

  const timeFormat = `HH:mm${timezone ? ' z' : ''}`;
  if ((!value.endValue && momentStartValue.isValid()) || momentStartValue.isSame(momentEndValue)) {
    return `${momentStartValue.format(`MM/DD ${timeFormat}`)}`;
  }

  return momentStartValue.isValid() && momentEndValue.isValid()
    ? `${momentStartValue.format('MM/DD HH:mm')} - ${momentEndValue.format(timeFormat)}`
    : '';
}
