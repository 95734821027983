import request from '../../../utils/request';
import { getAuthToken } from '../../../utils/auth';
import { api } from '../../../utils/url';
import { handleActionAPIUIError } from '../../utils';

const resourceType = 'stop-instants';

export function updateStopInstants(resources) {
  return async (setAppState, appStateModifiers) => {
    const authHeader = getAuthToken();

    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    if (resources) {
      options.body = JSON.stringify({
        data: resources
      });
    }

    try {
      const url = api(`/${resourceType}`);
      const response = await request(url, options);

      return response;
    } catch (err) {
      handleActionAPIUIError(err, resourceType, appStateModifiers.setAppErrorState);

      throw err;
    }
  };
}
