import React from 'react';
import * as notifications from '@client/_blessed/store/utils/toastNotifications';
import { toast } from 'react-toastify';
import { raiseToast } from '../../../components/Toaster';
import SystemToast from '../../../components/SystemToast';
import request from '../../../utils/request';
import { getAuthToken } from '../../../utils/auth';
import { startJSONAPIFetch, endJSONAPIFetch } from '../../utils';
import { api } from '../../../utils/url';
import { handleCustomError } from '../../../utils/errors';

const resourceType = 'stops';

export function createStops(body, legId) {
  return async (setState) => {
    const authHeader = getAuthToken();

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    try {
      if (body) {
        options.body = JSON.stringify(body);
      }

      const url = api(`/${resourceType}`);

      const response = await request(url, options);
      const stop = response.data && response.data[0];

      setState((prevState) => ({
        ...prevState,
        lastCreatedStopId: stop.id,
        lastCreatedStopParentId: legId
      }));

      return response;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, `Unable to access ${resourceType})`)}
        />,
        {
          position: toast.POSITION.BOTTOM_LEFT
        }
      );

      throw err;
    }
  };
}

export function retrieveStopById(id, query) {
  return async (setAppState) => {
    setAppState((prevState) => startJSONAPIFetch(prevState, `stops.${id}`));

    const authHeader = getAuthToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    try {
      let url = api(`/${resourceType}/${id}`);

      if (query) {
        url += `?${query}`;
      }

      const response = await request(url, options);

      setAppState((prevState) => endJSONAPIFetch(prevState, `stops.${id}`, response));

      return response;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, `Unable to access ${resourceType})`)}
        />,
        {
          position: toast.POSITION.BOTTOM_LEFT
        }
      );

      setAppState((prevState) => endJSONAPIFetch(prevState, `stops.${id}`, err));

      throw err;
    }
  };
}

export function updateStops(resources) {
  return async () => {
    const authHeader = getAuthToken();

    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    if (resources) {
      options.body = JSON.stringify({
        data: resources
      });
    }

    try {
      const url = api(`/${resourceType}`);
      const response = await request(url, options);

      return response;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, `Unable to access ${resourceType})`)}
        />,
        {
          position: toast.POSITION.BOTTOM_LEFT
        }
      );

      throw err;
    }
  };
}

export function deleteStops(resources) {
  return async () => {
    const authHeader = getAuthToken();

    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    if (resources) {
      options.body = JSON.stringify({
        data: resources
      });
    }

    try {
      const url = api(`/${resourceType}`);
      const response = await request(url, options);

      return response;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, `Unable to access ${resourceType})`)}
        />,
        {
          position: toast.POSITION.BOTTOM_LEFT
        }
      );

      throw err;
    }
  };
}

export function updateLegStopRelationsByUuid(payloadResources) {
  // interfaces can be found in _blessed/components/features/Dispatch/store/legStopActions/interfaces/index.ts
  // they will match const names
  return async () => {
    const legStopActionPayloads = payloadResources.map((payloadResource) => {
      const { attributes, id } = payloadResource;
      const legStopActionUuid = attributes?.legStopActionUuid;
      const legStopActionPayload = { uuid: id, legStopActionUuid };
      return legStopActionPayload;
    });

    const url = api('/leg-stop-relations');
    const body = JSON.stringify({ data: legStopActionPayloads });
    const options = { method: 'PATCH', body };
    try {
      const response = await request(url, options);
      return response;
    } catch (err) {
      const message = 'Unable to update leg stop action';
      notifications.sendFailureNotification(message);
    }
  };
}
