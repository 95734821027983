export const formatCompaniesDropdown = (companiesById) => {
  if (!companiesById) {
    return [];
  }

  return Object.keys(companiesById).map((companyId) => ({
    value: companyId,
    label: companiesById[companyId].attributes.name
  }));
};
