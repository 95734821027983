import React from 'react';
import Toaster, { raiseToast } from '../../../components/Toaster';
import SystemToast from '../../../components/SystemToast';
import request, { isFetchCanceled } from '../../../utils/request';
import { startJSONAPIFetch, endJSONAPIFetch } from '../../utils';
import { api } from '../../../utils/url';

const resourceType = 'resource-field-options';

export function retrieveTerminalApptOptions() {
  return async (setState) => {
    if (setState) {
      setState((prevState) => startJSONAPIFetch(prevState, 'terminalApptResourceFieldOptions'));
    }

    let response;

    try {
      const url = api(`/${resourceType}?fieldType=terminal_appt&resourceType=department`);
      response = await request(url);

      if (setState) {
        setState((prevState) => {
          const newState = endJSONAPIFetch(prevState, 'terminalApptResourceFieldOptions', response);
          return newState;
        });
      }

      return response;
    } catch (err) {
      if (!isFetchCanceled(err)) {
        raiseToast(
          <SystemToast
            type={SystemToast.Type.ERROR}
            message={`A problem occured while trying to access ${resourceType}`}
          />,
          {
            position: Toaster.Position.BOTTOM_LEFT
          }
        );
      }

      throw err;
    }
  };
}
