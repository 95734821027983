import { jwtDecode } from 'jwt-decode';
import cookies from 'js-cookie';
import getNestedObject from './getNestedObject';

export const HUB_TOKEN_KEY = 'hub-token';
export const REFRESH_TOKEN_KEY = 'refresh-token';
export const DEPARTMENT_KEY = 'da-department';
export const DOMAIN_NAME = 'drayalliance';
export const IMPERSONATE_TOKEN_KEY = 'impersonate-token';

export interface DecodedJWTToken {
  company: {
    name: string;
    uuid: string;
  };
  departments: Array<{
    companyUuid: string;
    name: string;
    nickname: string | null;
    permissions: Record<string, boolean>;
    roleUuid: string;
    tosVersionDate: string;
    type: string;
    uuid: string;
    verified: boolean | null;
  }>;
  deviceType: string;
  email1: string;
  email2: string;
  exp: number;
  firstName: string;
  iat: number;
  lastName: string;
  // eslint-disable-next-line camelcase
  mobile_phone: string | null;
  // eslint-disable-next-line camelcase
  office_phone: string | null;
  pushNotificationToken: string;
  uuid: string;
}

export function getRawHUBServiceAuthToken() {
  return cookies.get()[HUB_TOKEN_KEY] || null;
}

export function getRawHUBServiceImpersonateAuthToken() {
  return cookies.get()[IMPERSONATE_TOKEN_KEY] || null;
}

export function isValidHUBDashobardUser(departmentType: string) {
  return departmentType === 'admin';
}

export function getJWT() {
  let authToken = '';
  const tokens = cookies.get();

  if (tokens?.[HUB_TOKEN_KEY]) {
    authToken = tokens[HUB_TOKEN_KEY];
  } else {
    return null;
  }

  try {
    const jwt = jwtDecode<DecodedJWTToken>(atob(authToken));

    return jwt;
  } catch (e) {
    return null;
  }
}

export function isJWTValid() {
  const jwt = getJWT();

  if (jwt) {
    const currentTime = Math.floor(Date.now() / 1000);
    if (currentTime < jwt.exp) {
      return true;
    }
  }

  return false;
}

export function getRawAuthToken() {
  const tokens = cookies.get();

  if (tokens[HUB_TOKEN_KEY]) {
    return tokens[HUB_TOKEN_KEY];
  }
}

export function getAuthToken() {
  const token = cookies.get()[HUB_TOKEN_KEY];

  try {
    if (token) {
      const currentTime = Math.floor(Date.now() / 1000);
      const decodedAuthToken = atob(token);
      const jwt = jwtDecode<DecodedJWTToken>(decodedAuthToken);

      if (currentTime < jwt.exp) {
        // not expired
        return decodedAuthToken.replace(/['"]+/g, ''); // remove double quotes
      }
    }

    return null;
  } catch (e) {
    return null;
  }
}

export function setJWT(newData: any) {
  const newToken = { ...getJWT(), ...newData };
  cookies.set(HUB_TOKEN_KEY, newToken);
}

export const getDepartment = () => {
  try {
    let department = cookies.get('da-department');
    if (department) {
      department = JSON.parse(department);
    } else {
      department = getNestedObject(getJWT(), ['departments', 0]);
    }
    return department;
  } catch (e) {
    const department = getNestedObject(getJWT(), ['departments', 0]);
    return department;
  }
};

export const setDepartment = (department: any) => {
  if (isValidHUBDashobardUser(department)) {
    cookies.set('da-department', JSON.stringify(department));
  }
};

export const clearAuthTokens = () => {
  cookies.remove(DEPARTMENT_KEY);
  cookies.remove(DEPARTMENT_KEY, { path: '/', domain: `.${DOMAIN_NAME}.com` });
  cookies.remove(DEPARTMENT_KEY, { path: '/', domain: `test.${DOMAIN_NAME}.com` });
  cookies.remove(DEPARTMENT_KEY, { path: '/', domain: `stage.${DOMAIN_NAME}.com` });
  cookies.remove(DEPARTMENT_KEY, { path: '/', domain: `.${DOMAIN_NAME}.test` });
  cookies.remove(DEPARTMENT_KEY, { path: '/', domain: `.${DOMAIN_NAME}.stage` });
  cookies.remove(HUB_TOKEN_KEY);
  cookies.remove(HUB_TOKEN_KEY, { path: '/', domain: `.${DOMAIN_NAME}.com` });
  cookies.remove(HUB_TOKEN_KEY, { path: '/', domain: `test.${DOMAIN_NAME}.com` });
  cookies.remove(HUB_TOKEN_KEY, { path: '/', domain: `stage.${DOMAIN_NAME}.com` });
  cookies.remove(HUB_TOKEN_KEY, { path: '/', domain: `.${DOMAIN_NAME}.test` });
  cookies.remove(HUB_TOKEN_KEY, { path: '/', domain: `.${DOMAIN_NAME}.stage` });
  cookies.remove(REFRESH_TOKEN_KEY);
  cookies.remove(REFRESH_TOKEN_KEY, { path: '/', domain: `.${DOMAIN_NAME}.com` });
  cookies.remove(REFRESH_TOKEN_KEY, { path: '/', domain: `test.${DOMAIN_NAME}.com` });
  cookies.remove(REFRESH_TOKEN_KEY, { path: '/', domain: `stage.${DOMAIN_NAME}.com` });
  cookies.remove(REFRESH_TOKEN_KEY, { path: '/', domain: `.${DOMAIN_NAME}.test` });
  cookies.remove(REFRESH_TOKEN_KEY, { path: '/', domain: `.${DOMAIN_NAME}.stage` });
};

export const clearCookies = () => {
  Object.keys(cookies.get()).forEach((cookieName) => {
    cookies.remove(cookieName);
  });
};

export function getHUBServiceAuthToken() {
  try {
    let rawToken = getRawHUBServiceImpersonateAuthToken();

    if (!rawToken) {
      rawToken = getRawHUBServiceAuthToken();

      if (rawToken) {
        const decodedAuthToken = atob(rawToken);
        const authToken = decodedAuthToken.replace(/['"]+/g, '');

        return authToken;
      }
    }

    return rawToken;
  } catch (e) {
    return null;
  }
}
