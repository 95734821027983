import moment from 'moment-timezone';

export default function (date?: string, maxPickableDays?: number) {
  if (!maxPickableDays || !date) {
    return true;
  }
  const inputDateMoment = moment(date);

  const maxAllowedDate = moment().add(maxPickableDays, 'day');

  return maxAllowedDate.isAfter(inputDateMoment);
}
