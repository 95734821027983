import React from 'react';
import { Provider } from 'react-redux';
import configureReduxStore from '@client/configureStore';
import { rootReducer } from './_blessed/store';

// Create redux store with history
const store = configureReduxStore();

export default function RootProvider({ children }: { children?: React.ReactNode }) {
  return <Provider store={store}>{children}</Provider>;
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
