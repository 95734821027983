import React from 'react';
import moment from 'moment';
import Toaster, { raiseToast } from '../../../components/Toaster';
import SystemToast from '../../../components/SystemToast';
import request, { requestRules, isFetchCanceled, cancelRequestById } from '../../../utils/request';
import { getAuthToken } from '../../../utils/auth';
import { startJSONAPIFetch, endJSONAPIFetch, handleActionAPIUIError } from '../../utils';
import { api } from '../../../utils/url';

const resourceType = 'orders';
const includes =
  'secondaryContainerStatusDepartment,invoices,legGroups.graypoolEmpties.steamShippingLineAccount.company,legs.bill,legs.stops.attachments,legs.driver,legs.carrier.company,legs.legGroup,steamShippingLineAccount.company,billingAccount.company,returnAccount.company,deliveryAccount.company,pickupAccount.company,chassisProviderDepartment.company,ticketCases,legs.ticketCases,legs.truck,legs.bill.lineItems';
const newIncludes =
  'secondaryContainerStatusDepartment,invoices,legGroups.graypoolEmpties.steamShippingLineAccount.company,steamShippingLineAccount.company,billingAccount.company,returnAccount.company,deliveryAccount.company,pickupAccount.company,chassisProviderDepartment.company,ticketCases,legs.ticketCases';

export const finishOrder = (order) => async () => {
  try {
    const fetchOptions = {
      method: 'PATCH',
      body: JSON.stringify({
        data: [
          {
            id: order.id,
            attributes: {
              status: 'completed'
            }
          }
        ]
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: getAuthToken()
      }
    };
    await request(api(`/orders`), fetchOptions);
  } catch (e) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to finish order." />, {
      position: Toaster.Position.BOTTOM_LEFT
    });
    console.error(e);
    console.error('Could not finish order.');
  }
};

export function retrieveOrderCounts(opts, countUsingTags = false) {
  return async (setState) => {
    try {
      const today = JSON.stringify({
        start: moment().startOf('day').add(2, 'hours').toISOString(),
        end: moment().endOf('day').add(2, 'hours').add(1, 'minute').toISOString()
      });

      const tomorrow = JSON.stringify({
        start: moment().add(1, 'day').startOf('day').add(2, 'hour').toISOString(),
        end: moment().add(2, 'day').startOf('day').add(2, 'hour').add(1, 'minute').toISOString()
      });

      const yesterday = JSON.stringify({
        start: moment().subtract(1, 'day').startOf('day').add(2, 'hour').toISOString(),
        end: moment().startOf('day').add(2, 'hour').add(1, 'minute').toISOString()
      });

      const url = api(
        `/order/filterCount?countUsingTags=${countUsingTags}&today=${btoa(today)}&yesterday=${btoa(
          yesterday
        )}&tomorrow=${btoa(tomorrow)}`
      );

      const result = await request(url, opts);
      if (countUsingTags) {
        setState((prevState) => ({
          ...prevState,
          orderCountsByTag: { ...prevState.orderCountsByTag, ...result }
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          orderCounts: { ...prevState.orderCounts, ...result }
        }));
      }

      return result;
    } catch (err) {
      if (!isFetchCanceled(err)) {
        raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable get filter counts" />);
      }

      throw err;
    }
  };
}

export function dispatchPageRetrieveOrderCounts() {
  return retrieveOrderCounts({
    details: {
      id: 'dispatchPageRetrieveOrderCounts',
      rule: requestRules.TAKE_LAST
    }
  });
}

export function dispatchPageRetrieveOrderCountsUsingTags(countUsingTags) {
  return retrieveOrderCounts(
    {
      details: {
        id: 'dispatchPageRetrieveOrderCountsUsingTags',
        rule: requestRules.TAKE_LAST
      }
    },
    countUsingTags
  );
}

export const cancelOrder =
  (order, cancellationReasonUuid = null, cancellationReasonNotes = null) =>
  async () => {
    try {
      const authHeader = getAuthToken();
      const fetchOptions = {
        method: 'PATCH',
        body: JSON.stringify({
          data: [
            {
              id: order.id,
              attributes: {
                status: 'cancelled',
                cancellationReasonUuid,
                cancellationReasonNotes
              }
            }
          ]
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHeader}`
        }
      };
      await request(api(`/orders`), fetchOptions);
      raiseToast(<SystemToast type={SystemToast.Type.INFO} message="Order has been cancelled" />, {
        position: Toaster.Position.BOTTOM_LEFT
      });
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to cancel order." />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };

export const bulkCancelOrders = (orderUuids) => {
  const payload = orderUuids.map((uuid) => ({ id: uuid, attributes: { status: 'cancelled' } }));
  return async () => {
    try {
      const authHeader = getAuthToken();
      const fetchOptions = {
        method: 'PATCH',
        body: JSON.stringify({
          data: payload
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHeader}`
        }
      };
      await request(api(`/orders`), fetchOptions);
      raiseToast(<SystemToast type={SystemToast.Type.INFO} message="Orders have been cancelled" />);
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to cancel orders." />);
      throw err;
    }
  };
};

export function retrieveOrders(url, opts) {
  return async (setAppState) => {
    setAppState((prevState) => startJSONAPIFetch(prevState, 'orders'));

    try {
      const response = await request(url, opts);

      setAppState((prevState) => {
        const updatedState = endJSONAPIFetch(prevState, 'orders', response, { dataById: true });
        const legStopRelationshipsCreatedValidator = {};
        if (!updatedState.legStopRelationsByLegId) {
          updatedState.legStopRelationsByLegId = {};
        }

        if (response.included) {
          response.included.forEach((item) => {
            if (item.type === 'legStopRelation') {
              if (!legStopRelationshipsCreatedValidator[item.attributes.legUuid]) {
                legStopRelationshipsCreatedValidator[item.attributes.legUuid] = true;
                updatedState.legStopRelationsByLegId[item.attributes.legUuid] = [];
              }
              const legStopRelationships = updatedState.legStopRelationsByLegId[item.attributes.legUuid];
              legStopRelationships.push(item);
            }
          });
        }

        return updatedState;
      });

      return response;
    } catch (err) {
      if (!isFetchCanceled(err)) {
        raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
          position: Toaster.Position.BOTTOM_LEFT
        });

        setAppState((prevState) => endJSONAPIFetch(prevState, null, err));
      }

      throw err;
    }
  };
}

export function retrieveOrdersByQuery(opts, query) {
  let url = api(`/${resourceType}?page[size]=20&include=${newIncludes}`);

  if (query) {
    url += `&${query}`;
  }

  return retrieveOrders(url, opts);
}

export function dispatchPageRetrieveOrdersByQuery(query) {
  return retrieveOrdersByQuery(
    {
      details: {
        id: 'dispatchPageRetrieveOrders',
        rule: requestRules.TAKE_LAST
      }
    },
    query
  );
}

export function dispatchPageRetrieveOrders(url) {
  return retrieveOrders(url, {
    details: {
      id: 'dispatchPageRetrieveOrders',
      rule: requestRules.TAKE_LAST
    }
  });
}

export function cancelDispatchRetrieveOrdersRequest() {
  cancelRequestById('dispatchPageRetrieveOrders');
}

/**
 * @param {string} query
 */
export function appointmentBookingRetrieveOrders(query) {
  let url = api(`/${resourceType}?include=pickupAccount.company`);
  const opts = {
    details: {
      id: 'appointmentBookingRetrieveOrders'
    }
  };
  if (query) {
    url += `&${query}`;
  }
  return retrieveOrders(url, opts);
}

export function retrieveOrderById(id, { useNew } = { useNew: false }) {
  return async (setAppState) => {
    setAppState((prevState) => startJSONAPIFetch(prevState, `stops.${id}`));

    const authHeader = getAuthToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    try {
      const url = api(`/${resourceType}/${id}?include=${useNew ? newIncludes : includes}`);

      const response = await request(url, options);

      setAppState((prevState) => {
        const updatedState = endJSONAPIFetch(prevState, null, response, { dataById: true });
        const legStopRelationshipsCreatedValidator = {};

        if (!updatedState.legStopRelationsByLegId) {
          updatedState.legStopRelationsByLegId = {};
        }

        if (response.included) {
          response.included.forEach((item) => {
            if (item.type === 'legStopRelation') {
              if (!legStopRelationshipsCreatedValidator[item.attributes.legUuid]) {
                legStopRelationshipsCreatedValidator[item.attributes.legUuid] = true;
                updatedState.legStopRelationsByLegId[item.attributes.legUuid] = [];
              }

              const legStopRelationships = updatedState.legStopRelationsByLegId[item.attributes.legUuid];
              legStopRelationships.push(item);
            }
          });
        }

        return updatedState;
      });

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      setAppState((prevState) => endJSONAPIFetch(prevState, null, err));

      throw err;
    }
  };
}

export function updateOrders(orders) {
  return async (setAppState, appStateModifiers) => {
    const authHeader = getAuthToken();

    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    if (orders) {
      options.body = JSON.stringify({
        data: orders
      });
    }

    try {
      const url = api(`/${resourceType}`);
      const response = await request(url, options);

      return response;
    } catch (err) {
      handleActionAPIUIError(err, resourceType, appStateModifiers.setAppErrorState);

      throw err;
    }
  };
}

export const recoverOrder = (id) => async () => {
  const authHeader = getAuthToken();

  const options = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${authHeader}`
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      meta: {
        action: 'recover'
      },
      data: [
        {
          type: 'order',
          id
        }
      ]
    })
  };

  try {
    const url = api(`/v2/orders`);
    const response = await request(url, options);

    return response;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to recover order" />, {
      position: Toaster.Position.BOTTOM_LEFT
    });

    throw err;
  }
};

export function createSplitLeg(orderId, legId, newStopPosition) {
  return async (setState) => {
    const options = {
      method: 'PATCH',
      body: JSON.stringify({
        data: {
          orderId,
          legId,
          stopIndex: newStopPosition
        }
      })
    };

    try {
      const url = api(`/orders/split-leg`);

      const response = await request(url, options);

      setState((prevState) => ({
        ...prevState,
        lastCreatedStopPosition: newStopPosition,
        lastCreatedStopParentId: legId
      }));

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to split legs" />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}
