export async function getImageFileSrc(src, mimeType) {
  let result;

  if (src[0] === '/' && src[1] === '9') {
    const base64Flag = `data:${mimeType};base64,`;
    result = `${base64Flag}${src}`;
  } else {
    result = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(src);
    });
  }

  return result;
}
