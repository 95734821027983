import { useEffect, useState } from 'react';

const useKonami = (callback: () => void) => {
  const [keys, setKeys] = useState<string[]>([]);

  useEffect(() => {
    if (keys.join('') === 'uuddlrlrba') {
      callback();
      setKeys([]);
    }
  }, [keys]);

  const keyHandler = (e: any) => {
    let key: string | null = null;
    switch (e.key) {
      case 'ArrowUp':
        key = 'u';
        break;
      case 'ArrowDown':
        key = 'd';
        break;
      case 'ArrowLeft':
        key = 'l';
        break;
      case 'ArrowRight':
        key = 'r';
        break;
      case 'KeyA':
        key = 'b';
        break;
      case 'KeyB':
        key = 'a';
        break;
      default:
        key = null;
        break;
    }

    if (key) {
      setKeys((keyState) => {
        if (keyState.length >= 10) {
          return keyState.concat(key!).slice(1, 11);
        }

        return keyState.concat(key!);
      });
    } else {
      setKeys([]);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyHandler, { passive: true });

    return () => window.removeEventListener('keydown', keyHandler);
  }, [keyHandler]);
};

export default useKonami;
