import React from 'react';
import Toaster, { raiseToast } from '../../../components/Toaster';
import SystemToast from '../../../components/SystemToast';
import request from '../../../utils/request';
import { getAuthToken } from '../../../utils/auth';
import { api } from '../../../utils/url';

const resourceType = 'roles';

export function retrieveRoleById(id) {
  return async () => {
    try {
      const url = api(`/roles/${id}`);
      const response = await request(url);

      return response;
    } catch (error) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });
      throw error;
    }
  };
}

export function retrieveRoles() {
  return async (setAppState) => {
    const authHeader = getAuthToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    try {
      const url = api(`/${resourceType}`);
      const response = await request(url, options);

      const rolesById = {};
      if (response.data) {
        response.data.forEach((role) => {
          const { id } = role;
          rolesById[id] = role;
        });
      }

      setAppState((prevState) => ({
        ...prevState,
        'roles.id': rolesById
      }));

      return response;
    } catch (error) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });
      throw error;
    }
  };
}

export function retrieveRolesByDepartmentType(departmentType) {
  return async () => {
    const authHeader = getAuthToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    try {
      let url = api(`/${resourceType}`);

      if (departmentType) {
        url = `${url}?departmentType=${departmentType}`;
      }

      const response = await request(url, options);

      return response;
    } catch (error) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to retrieve roles by department type" />, {
        position: Toaster.Position.BOTTOM_LEFT
      });
      throw error;
    }
  };
}

export function patchUserRelationRole(userRelationId, roleId) {
  return async () => {
    const authHeader = getAuthToken();

    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    options.body = JSON.stringify({
      data: [
        {
          id: userRelationId,
          attributes: {
            roleUuid: roleId
          }
        }
      ]
    });

    try {
      const url = api('/user-department-relations');
      const response = await request(url, options);

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="User role has been updated" />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      return response;
    } catch (error) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to update user role" />, {
        position: Toaster.Position.BOTTOM_LEFT
      });
    }
  };
}
