import React from 'react';
import Toaster, { raiseToast } from '../../../components/Toaster';
import SystemToast from '../../../components/SystemToast';
import request from '../../../utils/request';
import { getAuthToken } from '../../../utils/auth';
import { endJSONAPIFetch, startJSONAPIFetch } from '../../utils';
import { api } from '../../../utils/url';

const resourceType = 'consigneeDeliveryPreference';

export const retrieveDeliveryPreferences = (departmentId, query) => async (setAppState) => {
  setAppState((prevState) => startJSONAPIFetch({ ...prevState }, resourceType));

  const authHeader = getAuthToken();

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authHeader}`
    },
    credentials: 'same-origin'
  };

  try {
    let url = api(`/consignee-delivery-preferences?departmentUuid=${departmentId}`);

    if (query) {
      url += `&${query}`;
    }

    const response = await request(url, options);

    setAppState((prevState) => endJSONAPIFetch(prevState, resourceType, response, { dataById: true }));

    return response;
  } catch (err) {
    raiseToast(
      <SystemToast type={SystemToast.Type.ERROR} message="Unable to access Consignee Delivery Preferences" />,
      {
        position: Toaster.Position.BOTTOM_LEFT
      }
    );

    setAppState((prevState) => endJSONAPIFetch(prevState, resourceType, err));

    throw err;
  }
};

export const updateDeliveryPreferences = (inputData) => async () => {
  if (!Array.isArray(inputData)) throw new Error('Update Delivery Preferences requires array input');
  const data = inputData;
  const authHeader = getAuthToken();

  const options = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${authHeader}`,
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin'
  };

  options.body = JSON.stringify({ data });

  try {
    const url = api(`/consignee-delivery-preferences`);
    const response = await request(url, options);

    return response;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to update consignee delivery preference" />, {
      position: Toaster.Position.BOTTOM_LEFT
    });

    throw err;
  }
};
