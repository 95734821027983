export interface QueryOptions {
  [key: string]: any;
}

export const removeEmptyQueryParams = (queryOptions: QueryOptions): QueryOptions => {
  if (!queryOptions) {
    return queryOptions;
  }

  return Object.keys(queryOptions).reduce((acc, key) => {
    const value = queryOptions[key];

    if (value !== null && value !== undefined && value !== '') {
      return {
        ...acc,
        [key]: value
      };
    }

    return acc;
  }, {});
};
