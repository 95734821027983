import React from 'react';
import Toaster, { raiseToast } from '../components/Toaster';
import SystemToast from '../components/SystemToast';
import { getDepartmentDisplayName } from '../utils/data-processing/department';
import { isFetchCanceled } from '../utils/request';

export function createErrorFieldId(id, sourceType, sourceParam) {
  return `${sourceType}_${id}_${sourceParam}`;
}

export function handleActionAPIError(err, resourceType) {
  if (!isFetchCanceled(err)) {
    if (err.code >= 500) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to connect to ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });
    } else if (err.code >= 400 && err.errors) {
      err.errors.forEach((error) => {
        raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={error.detail} />, {
          position: Toaster.Position.BOTTOM_LEFT
        });
      });
    }
  }
}

export function handleActionAPIUIError(err, resourceType, setAppErrorState) {
  if (!isFetchCanceled(err)) {
    if (err.code >= 500) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to connect to ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });
    } else if (err.code >= 400 && err.errors) {
      setAppErrorState((prevState) => {
        let { errorResourceIds } = prevState;

        if (!errorResourceIds) {
          errorResourceIds = {};
        } else {
          errorResourceIds = { ...errorResourceIds };
        }

        err.errors.forEach((error) => {
          errorResourceIds[createErrorFieldId(error.id, error.sourceType, error.sourceParam)] = true;
        });

        return {
          ...prevState,
          errors: err.errors,
          errorResourceIds
        };
      });
    }
  }
}

function normalizeJSONAPIData(src, data) {
  if (!data) {
    return src;
  }

  const result = src || {};

  if (Array.isArray(data)) {
    if (data.length > 0) {
      data.forEach((item) => {
        // TODO: Temp hack workaround for dropdowns until alternative for dropdown value accessors.
        item.label = getDepartmentDisplayName(item);

        const namespace = `${item.type}.id`;
        let collection = result[namespace];

        if (!collection) {
          // eslint-disable-next-line no-multi-assign
          collection = result[namespace] = {};
        }

        collection[item.id] = item;
      });
    }
  } else {
    // TODO:Temp hack workaround for dropdowns until alternative for dropdown value accessors.
    data.label = getDepartmentDisplayName(data);

    const fieldName = `${data.type}.id`;
    let resourceByIds = result[fieldName];

    if (!resourceByIds) {
      // eslint-disable-next-line no-multi-assign
      resourceByIds = result[fieldName] = {};
    }

    resourceByIds[data.id] = data;
  }

  return result;
}

export function startJSONAPIFetch(out, resourceType) {
  if (!resourceType) {
    return out;
  }

  const result = out ? { ...out } : {};

  const key = `fetch.${resourceType}.isFetching`;
  const errorsKey = `fetch.${resourceType}.errors`;

  result[errorsKey] = null;

  if (result[key]) {
    // eslint-disable-next-line no-plusplus
    ++result[key];
  } else {
    result[key] = 1;
  }

  return result;
}

export function endJSONAPIFetch(out, resourceType, response, opts = {}) {
  if (opts.includedById !== false) {
    opts.includedById = true;
  }

  if (!response) {
    return out;
  }

  const result = out ? { ...out } : {};

  // Success
  if (resourceType) {
    if (response.data !== undefined) {
      // Set the resource field to the response data.
      result[resourceType] = response.data;
    } else {
      // Error
      const errorsKey = `fetch.${resourceType}.errors`;

      result[errorsKey] = response;
    }

    const fetchingKey = `fetch.${resourceType}.isFetching`;

    if (result[fetchingKey] && result[fetchingKey] > 0) {
      // eslint-disable-next-line no-plusplus
      --result[fetchingKey];
    }
  }

  if (response.data !== undefined) {
    // Id map normalizing.
    if (opts.dataById) {
      normalizeJSONAPIData(result, response.data);
    }

    if (opts.includedById) {
      normalizeJSONAPIData(result, response.included);
    }
  }

  return result;
}
