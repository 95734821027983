import React from 'react';
import Toaster, { raiseToast } from '../../../components/Toaster';
import SystemToast from '../../../components/SystemToast';
import request from '../../../utils/request';
import getNestedObject from '../../../utils/getNestedObject';
import { api } from '../../../utils/url';

const resourceType = 'department-private-details';

export function updateDepartmentPrivateDetails(data, password) {
  let response;

  return async () => {
    try {
      const options = {
        method: 'PATCH',
        body:
          data &&
          JSON.stringify({
            data,
            password
          }),
        headers: {
          'Content-Type': 'application/json'
        }
      };

      const url = api(`/${resourceType}`);
      response = await request(url, options);
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={getNestedObject(err, ['errors', 0, 'message']) || `Unable to create ${resourceType}`}
        />,
        {
          position: Toaster.Position.BOTTOM_LEFT
        }
      );

      throw err;
    }

    return response;
  };
}
