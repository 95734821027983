import React from 'react';
import queryString from 'query-string';
import Toaster, { raiseToast } from '../../../components/Toaster';
import SystemToast from '../../../components/SystemToast';
import request from '../../../utils/request';
import { endJSONAPIFetch } from '../../utils';
import { api } from '../../../utils/url';

const resourceType = 'attachments';

export function retrieveAttachments(params) {
  return async (setAppState) => {
    try {
      let url = api(`/v2/${resourceType}?include=file`);

      if (params) {
        const qs = queryString.stringify(params);
        url += `&${qs}`;
      }

      const response = await request(url);

      if (setAppState) {
        setAppState((prevState) => {
          const newState = endJSONAPIFetch(prevState, null, response);

          return newState;
        });
      }

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export function retrieveAttachmentById(id, params) {
  return async (setAppState) => {
    try {
      let url = api(`/v2/${resourceType}/${id}?include=file`);

      if (params) {
        const qs = queryString.stringify(params);
        url += `&${qs}`;
      }

      const response = await request(url);

      if (setAppState) {
        setAppState((prevState) => {
          const newState = endJSONAPIFetch(prevState, null, response, { dataById: true });

          return newState;
        });
      }

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export function createAttachments(payload) {
  return async () => {
    try {
      const bulkResponse = await Promise.all(
        payload.map(async (data) => {
          const options = {
            method: 'POST',
            headers: {},
            body: data
          };

          const url = api(`/${resourceType}`);
          const response = await request(url, options);

          return response;
        })
      );

      raiseToast(
        <SystemToast type={SystemToast.Type.SUCCESS} message={`${payload.length} file(s) uploaded successfully.`} />,
        {
          position: Toaster.Position.BOTTOM_LEFT
        }
      );

      return bulkResponse;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export function createAttachment(data) {
  return async () => {
    const options = {
      method: 'POST',
      headers: {},
      body: data
    };

    try {
      const url = api(`/${resourceType}`);
      const response = await request(url, options);

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="File uploaded successfully." />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export function deleteAttachment(data) {
  return async () => {
    const options = {
      method: 'DELETE',
      headers: {},
      body: JSON.stringify(data)
    };

    try {
      const url = api(`/v2/${resourceType}`);
      const response = await request(url, options);

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="File deleted successfully." />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to delete ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}
