import i18next from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import CachedBackend from 'i18next-localstorage-backend';
import LocizeBackend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';

/**
 * Language must be detected or else we will get warnings
 *
 * Couldn't find any up to date i18next middleware so just roll our own
 */
const LanguageDetector = {
  init: () => {},
  type: 'languageDetector',
  detect: () => 'en',
  cacheUserLanguage: () => {}
};

i18next
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV !== 'production',
    supportedLngs: ['en'],
    fallbackLng: 'en',
    keySeparator: false,
    saveMissing: true,
    load: 'languageOnly', // optional - Load only languages without regions (eg. instead of en-US => en)

    /**
     * Namespaces to use
     */
    ns: ['common'],
    defaultNS: 'common',

    backend: {
      backends: [CachedBackend, LocizeBackend],
      backendOptions: [
        {
          // prefix for stored languages
          prefix: 'i18next_res_',
          // expiration
          expirationTime: 12 * 60 * 60 * 1000, // 12 hours
          // Version applied to all languages, can be overriden using the option `versions`
          defaultVersion: '',
          // language versions
          versions: {},
          // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
          store: window.localStorage
        },
        {
          referenceLng: 'en',
          projectId: 'e5ecd651-49b7-4bb5-acdc-f9d5ee18aaea',
          apiKey: process.env.LOCIZE_API_KEY
        } // Locize
      ]
    },
    react: {
      useSuspense: false
    }
  });

export default i18next;
