import Department from '@client/_blessed/store/entities/departments/model';
import { stopTypesToUIMappings } from '../../constants';

export function isStopDepartment(type: string): boolean {
  return type in stopTypesToUIMappings;
}

export function isShippingDepartment(type: string) {
  return type === 'shipping_line';
}

export function getDepartmentDisplayName(department?: Department | null) {
  if (!department) {
    return null;
  }

  return department.nickname || department.name;
}
