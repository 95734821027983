import React from 'react';
import request from '../../../utils/request';
import { getAuthToken } from '../../../utils/auth';
import { raiseToast } from '../../../components/Toaster';
import SystemToast from '../../../components/SystemToast/component';
import Toaster from '../../../components/Toaster/component';
import { api } from '../../../utils/url';

export const retrieveAllRegions = () => async (setAppState) => {
  const authHeader = getAuthToken();
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authHeader}`
    },
    credentials: 'same-origin'
  };

  try {
    const response = await request(api(`/regions`), fetchOptions);

    setAppState((prevState) => {
      let regionsById;

      if (prevState['region.id']) {
        regionsById = { ...prevState['region.id'] };
      } else {
        regionsById = {};
      }

      response.data.forEach((resource) => {
        regionsById[resource.id] = resource;
      });

      return {
        ...prevState,
        'region.id': regionsById
      };
    });
    return response;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} position={Toaster.Position.BOTTOM_CENTER} />);
    throw err;
  }
};

export const setDepartmentRegion = (department, region) => async () => {
  const authHeader = getAuthToken();
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${authHeader}`
    },
    credentials: 'same-origin'
  };

  fetchOptions.body = JSON.stringify({
    data: [
      {
        type: 'region',
        id: region.id !== null ? region.id || region : null
      }
    ]
  });

  try {
    const url = api(`/departments/${department.uuid || department}/relationships/regions`);
    const response = await request(url, fetchOptions);
    const regionsById = {};
    response.data.forEach((resource) => {
      regionsById[resource.id] = resource;
    });
    return response;
  } catch (err) {
    raiseToast(
      <SystemToast
        type={SystemToast.Type.ERROR}
        position={Toaster.Position.BOTTOM_CENTER}
        message="Department region could not be set"
      />
    );
    throw err;
  }
};
