import { createErrorFieldId } from '../utils';

export function removeNotification(id) {
  return (setAppErrorState) => {
    setAppErrorState((prevState) => {
      const newState = {
        ...prevState,
        errorToRemove: id
      };

      let { errorResourceIds } = newState;

      if (errorResourceIds) {
        errorResourceIds = { ...errorResourceIds };
        delete errorResourceIds[id];
        newState.errorResourceIds = errorResourceIds;
      }

      newState.errors = newState.errors.filter((error) => {
        const errorId = createErrorFieldId(error.id, error.sourceType, error.sourceParam);

        return errorId !== id;
      });

      return newState;
    });
  };
}

export function clearNotifications() {
  return (setAppErrorState) => {
    setAppErrorState(() => ({}));
  };
}
