import { LDInspection, LDEvaluationDetail } from 'launchdarkly-react-client-sdk';
import { datadogRum } from '@datadog/browser-rum';
import { User } from '@datadog/browser-core';
import { Env } from '@drayalliance/utils';
import manifest from '../manifest.json';

export const initRum = (env?: Env) => {
  if (env && env !== Env.Local) {
    const coimmitHash = manifest?.commitHash || '';

    datadogRum.init({
      applicationId: 'c6887ed2-d834-4475-90b3-95f3cc588751',
      clientToken: 'pubcc54ccab80ef3fa52bc6a7b32ed07c6e',
      site: 'datadoghq.com',
      service: 'hub',
      env: env.toString(),
      version: coimmitHash.slice(0, 8),
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      enableExperimentalFeatures: ['feature_flags']
    });

    datadogRum.startSessionReplayRecording();
  }
};

export const setDatadogUser = (user: User) => {
  datadogRum.setUser(user);
};

export const datadogInspector = (): LDInspection => ({
  type: 'flag-used',
  name: 'dd-inspector',
  method: (key: string, detail: LDEvaluationDetail) => {
    datadogRum.addFeatureFlagEvaluation(key, detail.value);
  }
});
