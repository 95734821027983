import React from 'react';
import qs from 'query-string';
import Toaster, { raiseToast } from '../../../components/Toaster';
import SystemToast from '../../../components/SystemToast';
import request from '../../../utils/request';
import { startJSONAPIFetch, endJSONAPIFetch } from '../../utils';
import { api } from '../../../utils/url';
import { handleCustomError } from '../../../utils/errors';

const resourceType = 'legs';

export function createDryRunLeg(legId, dryRunLegInformation) {
  return async (setAppState) => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        meta: {
          action: 'clone_legs_as_dry_run'
        },
        data: [
          {
            type: 'leg',
            id: legId,
            dryRunLegInformation
          }
        ]
      })
    };

    try {
      const url = api(`/legs`);

      const response = await request(url, options);

      setAppState((prevState) => endJSONAPIFetch(prevState, null, response));

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export function createLegs(body) {
  return async (setAppState) => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      if (body) {
        options.body = JSON.stringify(body);
      }

      const url = api(`/legs`);

      const response = await request(url, options);

      setAppState((prevState) => endJSONAPIFetch(prevState, null, response));

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export function retrieveLegsByQuery(query) {
  return async (setAppState) => {
    if (setAppState) {
      setAppState((prevState) => startJSONAPIFetch(prevState, 'legs'));
    }

    try {
      let url = api(`/legs`);

      if (query) {
        url += `?${qs.stringify(query)}`;
      }

      const response = await request(url);

      if (setAppState) {
        setAppState((prevState) => endJSONAPIFetch(prevState, 'legs', response));
      }

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      if (setAppState) {
        setAppState((prevState) => endJSONAPIFetch(prevState, 'legs', err));
      }

      throw err;
    }
  };
}

export function retrieveLegById(id, query) {
  return async (setAppState) => {
    try {
      let url = api(`/legs/${id}`);
      if (query) {
        url += `?${query}`;
      }

      const response = await request(url);

      setAppState((prevState) => endJSONAPIFetch(prevState, 'legs', response, { dataById: true }));

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export function updateLegs(resources) {
  return async () => {
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    if (resources) {
      options.body = JSON.stringify({
        meta: {
          action: 'admin_patch_legs'
        },
        data: resources
      });
    }

    try {
      const url = api(`/${resourceType}`);
      const response = await request(url, options);

      return response;
    } catch (error) {
      raiseToast(
        <SystemToast type={SystemToast.Type.ERROR} message={handleCustomError(error, 'Unable to update legs')} />,
        {
          position: Toaster.Position.BOTTOM_LEFT
        }
      );

      throw error;
    }
  };
}

export function deleteLegs(resources) {
  return async () => {
    const options = {
      method: 'DELETE'
    };

    if (resources) {
      options.body = JSON.stringify({
        data: resources
      });
    }

    try {
      const url = api(`/${resourceType}`);
      const response = await request(url, options);

      return response;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, `Unable to access ${resourceType}`)}
        />,
        {
          position: Toaster.Position.BOTTOM_LEFT
        }
      );
    }
  };
}

export const DELIVERY_UNMATCHED_STATUSES = ['leg_created', 'ready_for_pickup', 'in_transit', 'driver_assigned'];
export const DELIVERY_MATCHED_STATUSES = ['leg_created', 'ready_for_pickup', 'driver_assigned', 'in_transit'];

export function buildQueryForDeliveryLegs(departmentUuids, pageLimit, pageNumber, containerFilters) {
  const query = {};
  if (departmentUuids) {
    const filters = {};
    filters.class = 'delivery';
    filters.locationDepartmentUuids = departmentUuids;
    filters.unmatchedStatuses = DELIVERY_UNMATCHED_STATUSES;
    filters.matchedStatuses = DELIVERY_MATCHED_STATUSES;
    filters.containerNumbers = containerFilters;
    query.filters = btoa(JSON.stringify(filters));
  }

  if (pageLimit) {
    query['page[size]'] = pageLimit;
  }
  if (pageNumber) {
    query['page[number]'] = pageNumber;
  }

  return query;
}

export const BACKHAUL_UNMATCHED_STATUSES = ['leg_created', 'ready_for_pickup'];
export const BACKHAUL_MATCHED_STATUSES = ['leg_created', 'ready_for_pickup', 'driver_assigned', 'in_transit'];

export function buildQueryForBackhaulLegs(departmentUuids, pageLimit, pageNumber, containerFilters) {
  const query = {};
  if (departmentUuids) {
    const filters = {};
    filters.class = 'backhaul';
    filters.locationDepartmentUuids = departmentUuids;
    filters.unmatchedStatuses = BACKHAUL_UNMATCHED_STATUSES;
    filters.matchedStatuses = BACKHAUL_MATCHED_STATUSES;
    filters.containerNumbers = containerFilters;
    query.filters = btoa(JSON.stringify(filters));
  }

  if (pageLimit) {
    query['page[size]'] = pageLimit;
  }
  if (pageNumber) {
    query['page[number]'] = pageNumber;
  }

  return query;
}

export function retrieveDisplayableDeliveryLegsForDepartments(
  departmentUuids,
  pageLimit,
  pageNumber,
  containerFilters = []
) {
  return async (setAppState) => {
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/vnd.da.raw+json'
      }
    };

    const query = buildQueryForDeliveryLegs(departmentUuids, pageLimit, pageNumber, containerFilters);

    try {
      let url = api(`/${resourceType}`);

      const queryParamsKeys = Object.keys(query);
      if (queryParamsKeys.length) {
        queryParamsKeys.forEach((queryKey, index) => {
          if (index === 0) {
            url += '?';
          } else {
            url += '&';
          }
          url += `${queryKey}=${query[queryKey]}`;
        });
      }

      const response = await request(url, options);

      setAppState((prevState) => {
        const updatedState = endJSONAPIFetch(prevState, 'legs', response, { dataById: true });
        const legStopRelationshipsCreatedValidator = {};

        if (!updatedState.legStopRelationsByLegId) {
          updatedState.legStopRelationsByLegId = {};
        }

        if (response.included) {
          response.included.forEach((item) => {
            if (item.type === 'legStopRelation') {
              if (!legStopRelationshipsCreatedValidator[item.attributes.legUuid]) {
                legStopRelationshipsCreatedValidator[item.attributes.legUuid] = true;
                updatedState.legStopRelationsByLegId[item.attributes.legUuid] = [];
              }

              const legStopRelationships = updatedState.legStopRelationsByLegId[item.attributes.legUuid];
              legStopRelationships.push(item);
            }
          });
        }

        return updatedState;
      });
      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export function retrieveDisplayableBackhaulLegsForDepartments(
  departmentUuids,
  pageLimit,
  pageNumber,
  containerFilters = []
) {
  return async (setAppState) => {
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/vnd.da.raw+json'
      }
    };

    const query = buildQueryForBackhaulLegs(departmentUuids, pageLimit, pageNumber, containerFilters);

    try {
      let url = api(`/${resourceType}`);

      const queryParamsKeys = Object.keys(query);
      if (queryParamsKeys.length) {
        queryParamsKeys.forEach((queryKey, index) => {
          if (index === 0) {
            url += '?';
          } else {
            url += '&';
          }
          url += `${queryKey}=${query[queryKey]}`;
        });
      }

      const response = await request(url, options);

      setAppState((prevState) => {
        const updatedState = endJSONAPIFetch(prevState, 'legs', response, { dataById: true });
        const legStopRelationshipsCreatedValidator = {};

        if (!updatedState.legStopRelationsByLegId) {
          updatedState.legStopRelationsByLegId = {};
        }

        if (response.included) {
          response.included.forEach((item) => {
            if (item.type === 'legStopRelation') {
              if (!legStopRelationshipsCreatedValidator[item.attributes.legUuid]) {
                legStopRelationshipsCreatedValidator[item.attributes.legUuid] = true;
                updatedState.legStopRelationsByLegId[item.attributes.legUuid] = [];
              }

              const legStopRelationships = updatedState.legStopRelationsByLegId[item.attributes.legUuid];
              legStopRelationships.push(item);
            }
          });
        }

        return updatedState;
      });

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export function retrieveLegs(url, opts) {
  return async (setAppState) => {
    setAppState((prevState) => startJSONAPIFetch(prevState, 'legs'));
    try {
      const response = await request(url, opts);
      setAppState((prevState) => {
        const updatedState = endJSONAPIFetch(prevState, 'legs', response, { dataById: true });
        const legStopRelationshipsCreatedValidator = {};

        if (!updatedState.legStopRelationsByLegId) {
          updatedState.legStopRelationsByLegId = {};
        }

        if (response.included) {
          response.included.forEach((item) => {
            if (item.type === 'legStopRelation') {
              if (!legStopRelationshipsCreatedValidator[item.attributes.legUuid]) {
                legStopRelationshipsCreatedValidator[item.attributes.legUuid] = true;
                updatedState.legStopRelationsByLegId[item.attributes.legUuid] = [];
              }

              const legStopRelationships = updatedState.legStopRelationsByLegId[item.attributes.legUuid];
              legStopRelationships.push(item);
            }
          });
        }

        return updatedState;
      });

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export function retrieveBundleLegs(legId) {
  return async (setState) => {
    const url = api(`/legs?include=legGroup.graypoolEmpty,orders.steamShippingLineAccount&uuid=${legId}`);
    return retrieveLegs(url)(setState);
  };
}

export function linkUnlinkBackaul(resources) {
  return async () => {
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    if (resources) {
      options.body = JSON.stringify({
        meta: {
          action: 'link+unlink'
        },
        data: resources
      });
    }

    try {
      const url = api(`/${resourceType}`);
      const response = await request(url, options);

      return response;
    } catch (err) {
      const error = err.errors ? err.errors[0].message : err.message;

      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={error} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export function retrieveLegByLegNumber(legNumber) {
  return async () => {
    try {
      const url = api(`/legs?legNumber=${legNumber}&visibilityOverride=all`);
      const response = await request(url);

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to retrieve leg by leg number." />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export function addLegDeliveryLink(currLegId, deliveryLegId) {
  return async () =>
    linkUnlinkBackaul([
      {
        id: deliveryLegId,
        type: 'leg',
        attributes: {
          backhaulLegUuid: currLegId
        }
      }
    ])();
}

export function removeLegDeliveryLink(deliveryLegId) {
  return async () =>
    linkUnlinkBackaul([
      {
        id: deliveryLegId,
        type: 'leg',
        attributes: {
          backhaulLegUuid: null
        }
      }
    ])();
}

export function addLegBackhaulLink(currLegId, backhaulLegId) {
  return async () =>
    linkUnlinkBackaul([
      {
        id: currLegId,
        type: 'leg',
        attributes: {
          backhaulLegUuid: backhaulLegId
        }
      }
    ])();
}

export function removeLegBackhaulLink(currLegId) {
  return async () =>
    linkUnlinkBackaul([
      {
        id: currLegId,
        type: 'leg',
        attributes: {
          backhaulLegUuid: null
        }
      }
    ])();
}
