import React from 'react';
import Toaster, { raiseToast } from '../../../components/Toaster';
import SystemToast from '../../../components/SystemToast';
import request from '../../../utils/request';
import { getAuthToken } from '../../../utils/auth';
import { api } from '../../../utils/url';

const resourceType = 'files';

export function retrieveFile(fileType, id, filename, encodingType) {
  return async () => {
    const authHeader = getAuthToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin',
      cache: 'force-cache',
      details: {
        responseType: encodingType || 'blob'
      }
    };

    try {
      const url = api(`/_/${resourceType}/${id}/${filename}`);
      const response = await request(url, options);

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export function updateTermsOfService(markdown, pdf) {
  return async () => {
    const authHeader = getAuthToken();
    const formData = new FormData();
    formData.append('md', markdown);
    formData.append('mdSize', markdown.size);
    formData.append('pdf', pdf);
    formData.append('pdfSize', pdf.size);
    const options = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin',
      body: formData
    };
    try {
      const url = api(`/_/files/terms-of-service`);
      const response = await request(url, options);

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to upload Terms of Service" />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export function retrieveTOSVersion() {
  return async () => {
    const authHeader = getAuthToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin',
      cache: 'no-cache'
    };

    try {
      const url = api(`/version`);
      const response = await request(url, options);
      const { version } = response;
      const { tosVersionDate } = version;
      return tosVersionDate;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to access TOS Version" />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}
