const { BOT_USER_UUID } = require('@client/constants');

const RESERVED_ENTITIES = ['d21616a9-0896-7ca5-10ca-5c9ac4215f93', BOT_USER_UUID];

const isReserved = (uuid) => RESERVED_ENTITIES.includes(uuid);

module.exports = {
  RESERVED_ENTITIES,
  isReserved
};
