import SyncAltIcon from '@components/deprecatedTookit/icons/fa/regular/SyncAltIcon';
import { isToastActive, raiseToast } from '@client/components/Toaster';
import Button from '@components/deprecatedTookit/Button';
import SystemToast from '../components/SystemToast';

let swToastId = -1;

let currentVersionEtag = '';

export const hubHasNewVersion = async () => {
  // load the latest index.html and see if there is any etag changes.
  const hubResponse = await fetch('/');
  const newVersionEtag = (await hubResponse.headers.get('etag')) || '';

  if (!currentVersionEtag) {
    currentVersionEtag = newVersionEtag;
  }

  return currentVersionEtag !== newVersionEtag;
};

export const promptForHubUpdate = async () => {
  if (await hubHasNewVersion()) {
    if (!isToastActive(swToastId)) {
      swToastId = raiseToast(
        <SystemToast message="New version is available.">
          <Button
            async
            label="Refresh"
            theme="1"
            variant="outlined"
            icon={<SyncAltIcon />}
            onClick={() => {
              window.location.reload();
            }}
          />
        </SystemToast>,
        {
          autoClose: false,
          closeOnClick: false
        }
      );
    }
  }
};

export const removeLegacyServiceWorker = async () => {
  if (window.navigator && navigator.serviceWorker) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    // eslint-disable-next-line no-restricted-syntax
    for (const registration of registrations) {
      registration.unregister();
    }
  }
};

export default promptForHubUpdate;
