export function toggleLeftSidebar(open, component) {
  return (setState) => {
    setState((prevState) => {
      const newState = {
        ...prevState,
        'ui.leftSidebar.open': open
      };

      if (component) {
        newState['ui.leftSidebar.component'] = component;
      }

      return newState;
    });
  };
}

export function toggleRightSidebar(open, component) {
  return (setState) => {
    setState((prevState) => {
      const newState = {
        ...prevState,
        'ui.rightSidebar.open': open
      };

      newState['ui.rightSidebar.component'] = component;

      return newState;
    });
  };
}

export function toggleAppModal(open, component, disableClose) {
  return (setState) => {
    setState((prevState) => {
      const newState = {
        ...prevState,
        'app.modal.open': open
      };

      newState['app.modal.component'] = component || null;
      newState['app.modal.disableClose'] = disableClose || false;

      return newState;
    });
  };
}

export function openRightSidebar(component, opts) {
  return (setState) => {
    setState((prevState) => {
      const newState = {
        ...prevState,
        'ui.rightSidebar.open': true,
        'ui.rightSidebar.component': component,
        'ui.rightSidebar.props': opts
      };

      return newState;
    });
  };
}

export function closeRightSidebar() {
  return (setState) => {
    setState((prevState) => {
      const newState = {
        ...prevState,
        'ui.rightSidebar.open': false
      };

      return newState;
    });
  };
}

export function selectedItem(id) {
  return (setState) => {
    setState((prevState) => {
      const newState = {
        ...prevState,
        'ui.selectedItem': id
      };

      return newState;
    });
  };
}
