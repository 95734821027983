export default function getNestedObject<T = any>(nestedObj: any, pathArr: Array<string | number>): T | undefined {
  if (nestedObj && Array.isArray(pathArr)) {
    const pathLen = pathArr.length;
    const indexableTypes = ['object', 'string'];
    for (let i = 0; i < pathLen; i += 1) {
      if (!nestedObj || !indexableTypes.includes(typeof nestedObj)) {
        nestedObj = undefined;
        break;
      }

      nestedObj = nestedObj[pathArr[i]];
    }

    return nestedObj;
  }
}
