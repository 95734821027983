import React from 'react';
import qs from 'query-string';
import Toaster, { raiseToast } from '../../../components/Toaster';
import SystemToast from '../../../components/SystemToast';
import request from '../../../utils/request';
import { endJSONAPIFetch } from '../../utils';
import { getAuthToken } from '../../../utils/auth';
import { api } from '../../../utils/url';

const resourceType = 'trucks';

export function retrieveTrucksByQuery(query) {
  let response;

  return async (setState) => {
    try {
      if (!query) {
        throw new TypeError();
      }

      let url = api(`/${resourceType}`);
      if (query) {
        const queryStr = qs.stringify(query);
        url += `?${queryStr}`;
      }

      response = await request(url);
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    } finally {
      setState((prevState) => {
        const updatedState = endJSONAPIFetch(prevState, null, response, { dataById: true });

        return updatedState;
      });
    }

    return response;
  };
}

export function retrieveAllTrucks(query) {
  return async (setAppState) => {
    const authHeader = getAuthToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    try {
      let url = api(`/trucks`);

      if (query) {
        url += `?${query}`;
      }

      const response = await request(url, options);

      setAppState((prevState) => endJSONAPIFetch(prevState, 'trucks', response, { dataById: true }));

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      setAppState((prevState) => endJSONAPIFetch(prevState, resourceType, err));

      throw err;
    }
  };
}

export function createTruck(attributes) {
  return async () => {
    try {
      const authHeader = getAuthToken();
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authHeader}`
        },
        credentials: 'same-origin',
        body: JSON.stringify({
          data: [
            {
              type: 'truck',
              attributes
            }
          ]
        })
      };

      const url = api(`/${resourceType}`);
      const response = await request(url, options);

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Truck has been added" />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to add ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });
      throw err;
    }
  };
}

export function updateTrucks(trucks) {
  return async () => {
    const authHeader = getAuthToken();

    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    if (trucks) {
      options.body = JSON.stringify({
        data: trucks
      });
    }

    try {
      const url = api(`/${resourceType}`);
      const response = await request(url, options);
      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to update ${resourceType} data`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });
      throw err;
    }
  };
}

export function deleteTruck(truckId) {
  return async () => {
    const authHeader = getAuthToken();

    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    if (truckId) {
      options.body = JSON.stringify({
        data: [
          {
            type: 'truck',
            id: truckId
          }
        ]
      });
    }

    try {
      const url = api(`/${resourceType}`);
      const response = await request(url, options);

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Truck Deleted" />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to delete Truck" />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export function retrieveAllTrucksByCarrierId(id) {
  return async (setAppState) => {
    const authHeader = getAuthToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    try {
      const url = api(`/${resourceType}?departmentUuid=${id}`);
      const response = await request(url, options);

      setAppState((prevState) => endJSONAPIFetch(prevState, 'trucks', response, { dataById: true }));

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      setAppState((prevState) => endJSONAPIFetch(prevState, resourceType, err));

      throw err;
    }
  };
}
