import { Env, getAppUrl, getEnvFromHostname, HostVersion } from '@drayalliance/utils';
import qs from 'query-string';

export function getBaseApiUrlFromVersionAndEnv(env: Env | undefined, version: HostVersion) {
  if (!env) {
    throw new Error('Env is undefined!');
  }

  if (env === Env.Local) {
    return '';
  }
  if (env === Env.Production) {
    return `//api.drayalliance.com`;
  }
  switch (version) {
    case HostVersion.V1:
      return `//api.drayalliance.${env}`;
    case HostVersion.V2:
      return `//api.${env}.drayalliance.com`;
    default:
      throw new Error('Unhandled host version');
  }
}

export function api(path: string, prefixOverride?: string): string {
  if (path.startsWith('/')) {
    path = path.slice(1);
  }

  const { version, env } = getEnvFromHostname(window.location.hostname);
  const baseApiUrl = getBaseApiUrlFromVersionAndEnv(env, version);

  const prefixArray = [];
  if (env !== 'local' && !prefixOverride) {
    prefixArray.push('hub');
  } else {
    prefixArray.push('api');
    prefixArray.push('hub');
  }

  let prefixes;
  if (prefixOverride) {
    prefixes = prefixOverride;
  } else {
    prefixes = `/${prefixArray.join('/')}`;
  }

  return `${baseApiUrl}${prefixes}/${path}`;
}

export function urlToShipperDashOrder(orderUuid: string, { useLocal } = { useLocal: false }) {
  let shipperUrl = getAppUrl(window.location.hostname, 'shipper', 9002);
  if (useLocal) {
    shipperUrl = getAppUrl('localhost', 'shipper', 9002);
  }
  const suffix = `/dashboard/tracking/orders/${orderUuid}`;
  return shipperUrl + suffix;
}

export const urlToZendeskTicket = (ticketId: string) => `https://drayalliance.zendesk.com/agent/tickets/${ticketId}`;

export function openTab(query: object, blank?: boolean) {
  let url = `//${window.location.host}/hub/dashboard/dispatch`;

  if (query) {
    const queryStr = qs.stringify(query);

    url += `?${queryStr}`;
  }

  if (blank) {
    window.open(url, '_blank');
  } else {
    window.location.href = url;
  }
}
