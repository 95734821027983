import Toaster, { raiseToast } from '../../../components/Toaster';
import SystemToast from '../../../components/SystemToast';
import request from '../../../utils/request';
import { getAuthToken } from '../../../utils/auth';
import getNestedObject from '../../../utils/getNestedObject';
import { api } from '../../../utils/url';
import { handleCustomError } from '../../../utils/errors';

const resourceType = 'users';

export function updateUsers(users) {
  return async () => {
    const authHeader = getAuthToken();

    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    if (users) {
      options.body = JSON.stringify({
        data: users
      });
    }

    try {
      const url = api(`/v2/${resourceType}`);
      const response = await request(url, options);

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message={`Successfully updated ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      return response;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, `Unable to update ${resourceType}`)}
        />,
        {
          position: Toaster.Position.BOTTOM_LEFT
        }
      );

      throw err;
    }
  };
}

/**
 *
 * @param {import('@client/_blessed/store/entities/users/model').User} [user]
 * @returns
 */
export function deleteUser(user) {
  return async () => {
    const authHeader = getAuthToken();

    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    if (user) {
      options.body = JSON.stringify({
        data: [
          {
            type: 'user',
            id: user.uuid
          }
        ]
      });
    }

    try {
      const url = api(`/${resourceType}`);
      const response = await request(url, options);

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="User Deleted" />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to delete User" />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export function createUsers(users) {
  return async () => {
    const authHeader = getAuthToken();

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    if (users) {
      options.body = JSON.stringify({
        data: users
      });
    }

    try {
      const url = api(`/v2/${resourceType}`);
      const response = await request(url, options);

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="User created" />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      return response;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={getNestedObject(err, ['errors', 0, 'message']) || `Unable to create ${resourceType}`}
        />,
        {
          position: Toaster.Position.BOTTOM_LEFT
        }
      );

      throw err;
    }
  };
}
