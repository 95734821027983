export const formatNumberAsTypes = {
  percent: 1,
  currency: 2
};

export default function formatNumberAs(type, value) {
  if (!value) {
    return '';
  }

  switch (type) {
    case formatNumberAsTypes.percent:
      return `${value}%`;
    case formatNumberAsTypes.currency:
      return `$${value}`;
    default:
      return value;
  }
}
