import { User } from '@client/_blessed/store/entities/users/model';
import { DepartmentPrivateDetailsAttributes } from '@client/components/DepartmentDetailSidebar/components/DepartmentDetailSidebarBanking/types';
import { raiseToast, TOAST_POSITION } from '../../../components/Toaster';
import SystemToast from '../../../components/SystemToast';
import request from '../../../utils/request';
import { getAuthToken } from '../../../utils/auth';
import getNestedObject from '../../../utils/getNestedObject';
import { api } from '../../../utils/url';

const resourceType = 'departments';
// let includes = 'company,exportIngateOption,exportOutgateOption,importIngateOption,importOutgateOption,region';

export function retrieveLegBackhaulDepartments() {
  let response;

  return async () => {
    try {
      const url = api(`/v2/departments?class=backhaul`);
      response = await request(url);
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: TOAST_POSITION.BOTTOM_LEFT
      });

      throw err;
    }

    return response;
  };
}

export function retrieveLegDeliveryDepartments() {
  let response;

  return async () => {
    try {
      const url = api(`/v2/departments?class=delivery`);
      response = await request(url);
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: TOAST_POSITION.BOTTOM_LEFT
      });

      throw err;
    }

    return response;
  };
}

export function retrieveBankingInfoByDepartmentUUID(departmentUUID: string, password: string) {
  return async () => {
    let response: DepartmentPrivateDetailsAttributes | undefined;
    try {
      const url = api(`/v2/departments/${departmentUUID}/banking-info?password=${password}`);
      response = await request<DepartmentPrivateDetailsAttributes>(url);
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: TOAST_POSITION.BOTTOM_LEFT
      });

      throw err;
    }

    return response;
  };
}

/**
 * TODO: FIX THIS
 */
export function updateDepartments(departments: any[]) {
  return async () => {
    const authHeader = getAuthToken();

    const options: Record<string, any> = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    if (departments) {
      options.body = JSON.stringify({
        data: departments
      });
    }

    try {
      const url = api(`/v2/${resourceType}`);
      const response = await request(url, options);

      return response;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={getNestedObject(err, ['errors', 0, 'message']) || `Unable to create ${resourceType}`}
        />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );

      throw err;
    }
  };
}

/**
 * TODO: FIX THIS
 */
export function createDepartments(departments: any[]) {
  return async () => {
    const authHeader = getAuthToken();

    const options: Record<string, any> = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    if (departments) {
      options.body = JSON.stringify({
        data: departments
      });
    }

    try {
      const url = api(`/v2/${resourceType}`);
      const response = await request(url, options);

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Department created" />, {
        position: TOAST_POSITION.BOTTOM_LEFT
      });

      return response;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={getNestedObject(err, ['errors', 0, 'message']) || `Unable to create ${resourceType}`}
        />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );

      throw err;
    }
  };
}

export function deleteDepartment(departmentId: string) {
  return async () => {
    try {
      const authHeader = getAuthToken();

      const options: Record<string, any> = {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${authHeader}`
        },
        credentials: 'same-origin'
      };

      if (departmentId) {
        options.body = JSON.stringify({
          data: [
            {
              type: 'department',
              id: departmentId
            }
          ]
        });
      }

      const url = api(`/${resourceType}`);
      const response = await request(url, options);

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Department Deleted" />, {
        position: TOAST_POSITION.BOTTOM_LEFT
      });

      return response;
    } catch (error) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to delete department" />, {
        position: TOAST_POSITION.BOTTOM_LEFT
      });
    }
  };
}

export function addUserAndRoleToDepartment(userId: string, departmentId: string, roleId: string) {
  return async () => {
    try {
      const authHeader = getAuthToken();

      const postUserRelationOptions: Record<string, any> = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authHeader}`
        },
        credentials: 'same-origin'
      };

      if (departmentId) {
        postUserRelationOptions.body = JSON.stringify({
          data: [
            {
              type: 'department',
              id: departmentId
            }
          ]
        });
      }

      const postUserRelationURL = api(`/users/${userId}/relationships/departments`);
      const postUserRelationReponse = await request(postUserRelationURL, postUserRelationOptions);
      const userRelationId = postUserRelationReponse.data[0].id;

      const patchUserRelationOptions: Record<string, any> = {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${authHeader}`
        },
        credentials: 'same-origin'
      };

      if (roleId) {
        patchUserRelationOptions.body = JSON.stringify({
          data: [
            {
              id: userRelationId,
              attributes: {
                roleUuid: roleId
              }
            }
          ]
        });
      }

      const patchUserRelationURL = api(`/user-department-relations`);
      const patchUserRelationReponse = await request(patchUserRelationURL, patchUserRelationOptions);

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="User added to department" />, {
        position: TOAST_POSITION.BOTTOM_LEFT
      });

      return patchUserRelationReponse;
    } catch (error) {
      const message = (error as any).message || 'Unable to add user to department';
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={message} />, {
        position: TOAST_POSITION.BOTTOM_LEFT
      });
    }
  };
}

export function removeUserFromDepartment(userId: string, departmentId: string) {
  return async () => {
    try {
      const authHeader = getAuthToken();

      const options: Record<string, any> = {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${authHeader}`
        },
        credentials: 'same-origin'
      };

      if (departmentId) {
        options.body = JSON.stringify({
          data: [
            {
              type: 'department',
              id: departmentId
            }
          ]
        });
      }

      const url = api(`/users/${userId}/relationships/departments`);
      const response = await request(url, options);

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="User has been removed from department" />, {
        position: TOAST_POSITION.BOTTOM_LEFT
      });

      return response;
    } catch (error) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to remove user from department" />, {
        position: TOAST_POSITION.BOTTOM_LEFT
      });
    }
  };
}

export function retrieveDepartmentByIdWithUsers(departmentId: string) {
  return async () => {
    try {
      const url = api(`/v2/${resourceType}/${departmentId}/users`);

      const response = await request(url);

      return response.data as User[];
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: TOAST_POSITION.BOTTOM_LEFT
      });

      throw err;
    }
  };
}
