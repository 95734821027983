/**
 * @param {Object} props
 * @param {string} props.role
 * @param {string[]} [props.blacklistGroups]
 * @param {string[]} [props.whitelistGroups]
 * @returns {boolean | undefined}
 */
export function canIUse(props) {
  if (props.blacklistGroups) {
    if (props.blacklistGroups.indexOf(props.role) >= 0) {
      return false;
    }

    return true;
  }

  if (props.whitelistGroups) {
    if (props.whitelistGroups.indexOf(props.role) >= 0) {
      return true;
    }

    return false;
  }
}
