import { Env, getEnvFromHostname } from '@drayalliance/utils';
import amp from 'amplitude-js';
import { getJWT, getRawHUBServiceImpersonateAuthToken } from './auth';

export function createEventProperties(screen: string, category: string, data: object) {
  // let hubServiceAuthToken = getHUBServiceAuthToken();
  const jwt = getJWT();
  const impersonationToken = getRawHUBServiceImpersonateAuthToken();
  let impersonationUuid = null;
  let impersonationName = null;

  if (impersonationToken && jwt) {
    impersonationUuid = jwt.uuid;
    impersonationName = `${jwt.firstName} ${jwt.lastName}`;
  }

  return {
    ...data,
    screen, // (ie. "dispatch", "settings", "reports")
    category, // (ie. "page view", "click", "success", "error")
    // component (ie. "bundle/sidebar", "settings/people") optional for now
    impersonating: impersonationName,
    impersonationUuid
  };
}

export function createUserProperties() {
  const jwt = getJWT();

  if (jwt && jwt.email1) {
    return {
      email: jwt.email1,
      firstName: jwt.firstName,
      lastName: jwt.lastName
    };
  }
}

export function initAnalytics(userId?: string) {
  userId = userId || getJWT()?.uuid;
  const { env } = getEnvFromHostname(window.location.hostname);

  let apiKey = '90dcd318b758e60fab101b2bc7f64231';
  if (env && env === Env.Production) {
    // prod
    apiKey = 'b2b8d8d7d2d86a05d8c444ac7185447a';
  }

  if (userId) {
    amp.getInstance().init(apiKey, userId);
  } else {
    amp.getInstance().init(apiKey);
  }
}

export function logAnalyticsEvent(event: string, screen: string, category: string, ep: object) {
  event = event.toLowerCase();
  category = category.toLowerCase();
  const eventName = `${category}: ${event}`;
  const eventProperties = createEventProperties(screen, category, ep);
  amp.getInstance().logEvent(eventName, eventProperties);
}

export const logAnalyticsPageView = (screen: string, ep = {}) => {
  logAnalyticsEvent(screen, screen, 'page view', ep);
};

export const logAnalyticsElementView = (element: string, screen: string, ep = {}) => {
  logAnalyticsEvent(element, screen, 'element View', ep);
};

export const logAnalyticsPageLoad = (screen: string, ep = {}) => {
  logAnalyticsEvent(screen, screen, 'page load', ep);
};

export const logAnalyticsClick = (action: string, screen: string, ep = {}) => {
  logAnalyticsEvent(action, screen, 'click', ep);
};

export function setAnalyticsUserId(id: string) {
  amp.getInstance().setUserId(id);
}

export function logDispatchPageInitialLoadTime(loadTimeMs: number, ep = {}) {
  logAnalyticsEvent('dispatch page initial load', 'dispatch page', 'perf', {
    ...ep,
    loadTimeMs
  });
}

export function setUserProperties() {
  const userProperties = createUserProperties();
  if (userProperties) {
    amp.getInstance().setUserProperties(userProperties);
  }
}
