import queryString from 'query-string';
import { getJWT, getDepartment, getAuthToken } from './auth';
import { QueryOptions, removeEmptyQueryParams } from './format';

export const requestRules = {
  TAKE_LAST: 'TAKE_LAST'
};

let requestConfig: {
  use?: (url: string, options: RequestInit) => void;
} = {
  use: undefined
};

const requestCache: Record<string, { controller: AbortController | null; fetchPromise: Promise<Response> | null }> = {};

export function cancelRequestById(id: string) {
  const requestDetails = requestCache[id];

  if (requestDetails) {
    if (requestDetails.controller) {
      requestDetails.controller.abort();
    }
  }
}

export function isFetchCanceled(err: { code: number }) {
  if (err.code === 20) {
    return true;
  }

  return false;
}

function parseJSON<T>(response: Response): Promise<T> | undefined {
  if (response.status === 204 || response.status === 205 || response.headers.get('content-length') === '0') {
    return undefined;
  }

  return response.json();
}

function parseBlob<T>(response: Response): Promise<T> | undefined {
  if (response.status === 204 || response.status === 205) {
    return undefined;
  }

  return response.blob() as Promise<T>;
}

function parseArrayBuffer<T>(response: Response): Promise<T> | undefined {
  if (response.status === 204 || response.status === 205) {
    return undefined;
  }

  return response.arrayBuffer() as Promise<T>;
}

function parseText<T>(response: Response): Promise<T> | undefined {
  if (response.status === 204 || response.status === 205) {
    return undefined;
  }

  return response.text() as Promise<T>;
}

async function checkStatus(response: Response | undefined) {
  if (!response) {
    return undefined;
  }

  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const resposneObj = await response.json();

  const error: Error & { errors?: any; code?: number; responseObj?: any } = new Error(resposneObj.message);
  error.errors = resposneObj.errors;
  error.code = response.status;
  error.responseObj = resposneObj;
  throw error;
}

function parseURL(url: string) {
  const parser = document.createElement('a');
  const searchObject: Record<string, unknown> = {};
  let split;
  let i;
  // Let the browser do the work
  parser.href = url;
  // Convert query string to object
  const queries = parser.search.replace(/^\?/, '').split('&');
  for (i = 0; i < queries.length; i += 1) {
    split = queries[i].split('=');
    // eslint-disable-next-line prefer-destructuring
    searchObject[split[0]] = split[1];
  }
  return {
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    search: parser.search,
    searchObject,
    hash: parser.hash
  };
}

export default async function request<T = any>(
  url: string,
  opts?: Partial<
    RequestInit & {
      query?: QueryOptions;
      details?: { id: string; rule: string; responseType?: string };
    }
  >
): Promise<T | undefined> {
  if (!url) {
    return;
  }

  let isDomesticDomain = true;
  let parser: typeof parseJSON | typeof parseBlob | typeof parseArrayBuffer | typeof parseText = parseJSON;
  let fetchPromise = null;
  let requestDetails = null;
  let query = null;

  const headers = new Headers({
    'da-source': `web.hub`,
    'Content-Type': 'application/json'
  });
  const defaultOptions: RequestInit = {
    method: 'GET',
    headers
  };

  // Do **NOT** set any 'Content-Type' for POST requests made with FormData
  if ((opts?.method === 'POST' || opts?.method === 'PUT') && opts.body && opts.body instanceof FormData) {
    if (
      defaultOptions.headers &&
      defaultOptions.headers instanceof Headers &&
      defaultOptions.headers.has('Content-Type')
    ) {
      defaultOptions.headers.delete('Content-Type');
    }
    if (opts?.headers && 'Content-Type' in opts.headers) {
      delete opts?.headers?.['Content-Type'];
    }
  }

  let options = defaultOptions;

  // start middleware.

  // Middleware
  if (requestConfig) {
    if (requestConfig.use) {
      requestConfig.use(url, options);
    }
  }

  const parsedUrl = parseURL(url);
  // Send auth information only for domestic/same domain apis.
  if (url[0] === '/' && url[1] !== '/' && !window.location.hostname.includes('local')) {
    const hostnameChunks = window.location.hostname.split('.');

    if (hostnameChunks.length > 2) {
      // remove subdomain. ex. shipper.drayalliance.com
      hostnameChunks.shift();
      url = `${window.location.protocol}//www.${hostnameChunks.join('.')}${url}`;
    }
  } else if (
    !window.location.hostname.includes('local') &&
    url[0] !== '/' &&
    !['drayalliance.test', 'drayalliance.stage', 'drayalliance.com'].some(
      (host) => parsedUrl.hostname.indexOf(host) === parsedUrl.hostname.length - host.length
    )
  ) {
    isDomesticDomain = false;
  }

  const authToken = getAuthToken();
  if (isDomesticDomain) {
    options.credentials = 'include';
    if (authToken) {
      headers.set('Authorization', `Bearer ${authToken}`);
      defaultOptions.headers = headers;
    }
  } else {
    options.credentials = 'omit';
  }

  if (
    !/api.*\/hub\/login$/.test(url) &&
    !/api.*\/hub\/user\/password-reset\/.*$/.test(url) &&
    !/api.*\/hub\/user$/.test(url)
  ) {
    const jwt = getJWT();
    const department = getDepartment();

    if (jwt && jwt.departments) {
      if (department) {
        headers.set('da-department', department.uuid);
      } else {
        console.error('Missing department header.');
      }
    }
  }

  // end middleware

  if (opts) {
    options = {
      ...defaultOptions,
      ...opts
    };
    options.headers = defaultOptions.headers;

    if (opts.headers) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(opts.headers)) {
        headers.set(key, value);
      }

      options.headers = headers;
    }

    if (opts.query && typeof opts.query === 'object') {
      const nonEmptyQuery = removeEmptyQueryParams(opts.query);
      query = queryString.stringify(nonEmptyQuery, {
        arrayFormat: 'comma',
        encode: false,
        skipNull: false
      });
    }

    if (typeof query === 'string') {
      if (query[0] !== '?') {
        query = `?${query}`;
      } else {
        query = `${query}`;
      }
    }

    if (query) {
      url += query;
    }

    if (opts.details) {
      switch (opts.details.responseType) {
        case 'blob':
          parser = parseBlob;
          break;
        case 'text':
          parser = parseText;
          break;
        case 'arrayBuffer':
          parser = parseArrayBuffer;
          break;
        default:
      }

      // Add request caching.
      if (opts.details.id) {
        requestDetails = requestCache[opts.details.id];

        if (requestDetails) {
          // Handle side effects.
          if (opts.details.rule === requestRules.TAKE_LAST) {
            // Only the last requested response of the same id will be handled.
            // Otherwise, an abort error of code 20 will be thrown.
            if (requestDetails.controller) {
              requestDetails.controller.abort();
            }

            requestDetails.controller = new AbortController();
            options.signal = options.signal || requestDetails.controller.signal;
            requestDetails.fetchPromise = fetch(url, options);
          }
        } else {
          // Only the last requested response of the same id will be handled.
          // Gives future ability to use the id for debugging features.
          const controller = new AbortController();
          options.signal = options.signal || controller.signal;
          // eslint-disable-next-line no-multi-assign
          requestDetails = requestCache[opts.details.id] = {
            controller,
            fetchPromise: fetch(url, options)
          };
        }

        fetchPromise = requestDetails.fetchPromise;
      }
    }
  }

  // Handle standard fetching.
  if (!fetchPromise) {
    fetchPromise = fetch(url, options);
  }

  let response;
  let responseStatusChecked = null;
  let responseParsed;

  try {
    response = await fetchPromise;
    if (requestDetails) {
      requestDetails.controller = null;
      requestDetails.fetchPromise = null;
    }
  } finally {
    responseStatusChecked = await checkStatus(response);

    if (responseStatusChecked) {
      responseParsed = await parser<T>(responseStatusChecked);
    }
  }

  return responseParsed;
}

export function setRequestConfig(
  opts?: RequestInit & { query?: QueryOptions; details?: { id: string; rule: string; responseType: string } }
) {
  if (opts) {
    requestConfig = {
      ...requestConfig,
      ...opts
    };
  }
}
