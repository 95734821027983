import React from 'react';
import { CSVHandler } from '@client/_blessed/components/features/ArAp/features/BulkInvoiceGeneration/CSVHandler';
import { getAuthToken } from '@client/utils/auth';
import { api } from '@client/utils/url';
import Toaster, { raiseToast } from '../../../components/Toaster';
import SystemToast from '../../../components/SystemToast';
import request from '../../../utils/request';
import { startJSONAPIFetch, endJSONAPIFetch } from '../../utils';
import getNestedObject from '../../../utils/getNestedObject';

const resourceType = 'invoices';
export const finishInvoice = (invoice, order) => async () => {
  try {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({
        meta: {
          action: 'admin_create_invoice'
        },
        data: [
          {
            type: 'invoice',
            id: invoice.id
          }
        ]
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: getAuthToken()
      }
    };
    await request(api(`/invoices`), fetchOptions);
  } catch (e) {
    raiseToast(
      <SystemToast
        type={SystemToast.Type.ERROR}
        message={`Unable to create invoice for Order #${getNestedObject(order, ['attributes', 'orderNumber'])}`}
      />,
      {
        position: Toaster.Position.BOTTOM_LEFT
      }
    );
    console.error(e);
    console.error('Could not create invoice.');
    throw e;
  }
};

export function retrieveInvoices(query) {
  return async (setAppState) => {
    setAppState((prevState) => startJSONAPIFetch(prevState, 'invoices'));

    const authHeader = getAuthToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    try {
      let url = api(`/${resourceType}`);

      if (query) {
        url += `?${query}`;
      }

      const response = await request(url, options);

      setAppState((prevState) => endJSONAPIFetch(prevState, 'invoices', response));

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      setAppState((prevState) => endJSONAPIFetch(prevState, 'invoices', err));

      throw err;
    }
  };
}

export function retrieveInvoiceById(id) {
  return async (setAppState) => {
    const authHeader = getAuthToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    try {
      const url = api(
        `/${resourceType}/${id}?include=lineItems.billLineItems.bill.leg.stop,lineItems.billLineItems.bill.leg.carrier`
      );

      const response = await request(url, options);

      setAppState((prevState) => endJSONAPIFetch(prevState, null, response));

      return response;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to access ${resourceType}`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });

      throw err;
    }
  };
}

export async function getInvoiceByLineItemId(lineItemUuid) {
  const authHeader = getAuthToken();

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authHeader}`
    },
    credentials: 'same-origin'
  };

  try {
    const url = api(`/invoices/lineItemUuid/${lineItemUuid}`);

    return await request(url, options);
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`Unable to delete ${resourceType}`} />, {
      position: Toaster.Position.BOTTOM_LEFT
    });
  }
}

export function setInvoiceReadyForInvoicing(id, order) {
  return async () => {
    const authHeader = getAuthToken();

    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${authHeader}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: [
          {
            type: 'invoice',
            id,
            attributes: { readyForInvoicing: true }
          }
        ]
      }),
      credentials: 'same-origin'
    };

    try {
      const url = api(`/${resourceType}`);

      const response = await request(url, options);

      raiseToast(
        <SystemToast
          type={SystemToast.Type.SUCCESS}
          message={`Invoice for Order #${getNestedObject(order, [
            'attributes',
            'orderNumber'
          ])} set to ready for invoice`}
        />,
        {
          position: Toaster.Position.BOTTOM_LEFT
        }
      );
      return response;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={`Unable to patch invoice for Order #${getNestedObject(order, [
            'attributes',
            'orderNumber'
          ])} to ready for invoice`}
        />,
        {
          position: Toaster.Position.BOTTOM_LEFT
        }
      );

      throw err;
    }
  };
}

export async function uploadBulkInvoice(bulkInvoice) {
  return CSVHandler.handleSubmit(bulkInvoice);
}
