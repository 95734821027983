import request from '../../../utils/request';
import { getAuthToken } from '../../../utils/auth';
import { raiseToast } from '../../../components/Toaster';
import SystemToast from '../../../components/SystemToast/component';
import Toaster from '../../../components/Toaster/component';
import { api } from '../../../utils/url';

export const retrieveAllShipperPreferences = () => async (setAppState) => {
  const authHeader = getAuthToken();
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authHeader}`
    },
    credentials: 'same-origin'
  };

  try {
    const response = await request(api(`/shipper-preferences`), fetchOptions);

    setAppState((prevState) => ({
      ...prevState,
      'shipperPreferences.shipperId': response.reduce((acc, pref) => {
        acc[pref.shipperUUID] = pref;
        return acc;
      }, {})
    }));
    return response;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} position={Toaster.Position.BOTTOM_CENTER} />);
    throw err;
  }
};

export const updateShipperPreference = (shipperPreference) => async (setAppState) => {
  const authHeader = getAuthToken();
  const fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${authHeader}`
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      data: shipperPreference
    })
  };

  try {
    const response = await request(api(`/shipper-preferences`), fetchOptions);

    setAppState((prevState) => ({
      ...prevState,
      'shipperPreferences.shipperId': {
        ...(prevState['shipperPreferences.shipperId'] || {}),
        [shipperPreference.shipperUUID]: {
          ...(prevState['shipperPreferences.shipperId'][shipperPreference.shipperUUID] || {}),
          ...shipperPreference
        }
      }
    }));

    return response;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} position={Toaster.Position.BOTTOM_CENTER} />);
    throw err;
  }
};
