import React from 'react';

import { toast } from 'react-toastify';
import { getAuthToken } from '../../../utils/auth';
import SystemToast from '../../../components/SystemToast';
import { raiseToast } from '../../../components/Toaster';
import { api } from '../../../utils/url';
import request from '../../../utils/request';
import {
  Bundle,
  FetchBundleByLegResponse,
  SetBundleSyncResponse,
  SplitBundleRequest,
  SplitBundleResponse
} from './models';
import { endJSONAPIFetch } from '../../utils';
import { SyncAppointmentOption } from './models/SyncAppointmentOption';
import { SyncCarrierOption } from './models/SyncCarrierOption';
import { BundleType } from './models/BundleTypes';
import { PatchBundleLegRequest } from './models/PatchBundleLegRequest';
import { handleCustomError } from '../../../utils/errors';

const headers = new Headers({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${getAuthToken()}`
});

const getBaseOptions = (): RequestInit => ({
  method: 'GET',
  headers,
  credentials: 'same-origin'
});

/**
 * Fetch bundles by leg uuids
 *
 * @param legUuids - Uuids to fetch by
 * @param onExecuteAction - app action context to be passed in if global state changes are wanted
 */
export const fetchBundlesByLeg = async (legUuids: string[], onExecuteAction?: (fn: any) => void) => {
  const options = getBaseOptions();

  let response: FetchBundleByLegResponse;
  try {
    /*
    // Uncomment this block to get around crash when leg doesn't belong to a bundle
    if (legUuids.length === 0) {
      return { uuid: '', bundleType: '', numberOfLegs: 0 };
    }
    */
    const url = api(`/bundle?legUuids=${legUuids.join(',')}`);

    response = (await request(url, options)) as FetchBundleByLegResponse;
  } catch (error) {
    raiseToast(
      <SystemToast
        type={SystemToast.Type.ERROR}
        message={handleCustomError(error, 'Unable to access data for bundle')}
      />,
      {
        position: toast.POSITION.BOTTOM_LEFT
      }
    );

    throw error;
  }

  if (onExecuteAction) {
    onExecuteAction((setState: any) => {
      setState((prevState: any) => endJSONAPIFetch(prevState, 'bundles', response));
    });
  }

  return response.data[0];
};

export interface BundleSyncOverride {
  appointmentNumber?: number | string | null;
  appointmentStart?: string | null;
  appointmentEnd?: string | null;
}

export interface BundleSyncData {
  bundleUuid: string;
  primaryStopInstant: string;
  appointmentOverride?: BundleSyncOverride;
  sync: boolean;
}

/**
 * Set syncing between a bundles legs
 *
 * @param data - Data for syncing a bundles stop instants
 * @param onExecuteAction - Global app state action execution function to allow for state updates
 */
export const setBundleSync = async (data: BundleSyncData, onExecuteAction?: Function, postMergeSync?: boolean) => {
  const options = getBaseOptions();
  options.method = 'PATCH';
  (options as any).body = JSON.stringify(data);

  let response: SetBundleSyncResponse;
  try {
    const url = api('/bundle/sync');

    response = (await request(url, options)) as SetBundleSyncResponse;
  } catch (error) {
    if (postMergeSync) {
      const failToSyncPostMergeMessage =
        'Bundle succeeded, but appointments are not synced due to invalid appointment time. Please adjust appointment times and sync them manually.';
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={failToSyncPostMergeMessage} />, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(error, 'Unable to set bundle sync status')}
        />,
        {
          position: toast.POSITION.BOTTOM_LEFT
        }
      );
    }

    throw error;
  }

  /** Hack for bundles in main state to keep sidebar updates in sync with main dispatch page */
  if (onExecuteAction) {
    onExecuteAction((setState: any) => {
      setState((prevState: any) => {
        const newState = {
          ...prevState
        };

        if (newState.bundles && Array.isArray(newState.bundles)) {
          const foundIndex = newState.bundles.findIndex((b: Bundle) => b.uuid === response.uuid);

          /** Just replace the whole thing */
          if (foundIndex >= 0) {
            newState.bundles.splice(foundIndex, 1, response);
          } else {
            newState.bundles.push(response);
          }
        }

        return newState;
      });
    });
  }

  return response;
};

export const syncBundleDepartments = async (data: {
  bundleUuid: string;
  positionToSyncFrom: number;
  positionToSyncTo: number;
}): Promise<void> => {
  const options = getBaseOptions();
  options.method = 'POST';
  (options as any).body = JSON.stringify(data);

  try {
    const url = api('/bundle/sync/departments');

    await request(url, options);
  } catch (error) {
    raiseToast(
      <SystemToast
        type={SystemToast.Type.ERROR}
        message={handleCustomError(error, 'Unable to sync bundle departments')}
      />,
      {
        position: toast.POSITION.BOTTOM_LEFT
      }
    );

    throw error;
  }
};

export interface FetchAppointmentSyncOptionsResponse {
  data: {
    appointmentOptions: SyncAppointmentOption[];
  };
}

/**
 * Fetch bundle sync options for an existing bundle
 *
 * @param bundleUuid - Uuid of the bundle
 * @param stopInstantUuid - Stop instant uuid
 */
export const getBundleSyncOptions = async (bundleUuid: string, stopInstantUuid: string) => {
  const options = getBaseOptions();

  try {
    const url = api(`/bundle/sync-options?bundleUuid=${bundleUuid}&stopInstantUuid=${stopInstantUuid}`);

    const res: FetchAppointmentSyncOptionsResponse = (await request(
      url,
      options
    )) as FetchAppointmentSyncOptionsResponse;

    return res.data.appointmentOptions;
  } catch (error) {
    raiseToast(
      <SystemToast
        type={SystemToast.Type.ERROR}
        message={handleCustomError(error, 'Unable to access data for bundle')}
      />,
      {
        position: toast.POSITION.BOTTOM_LEFT
      }
    );
    throw error;
  }
};

interface FetchBundleMergeSyncOptionsRequest {
  bundleUuid: string;
  legNumber: string;
  prepend: boolean;
}

export interface FetchBundleMergeSyncOptionsResponse {
  data: {
    appointmentOptions: SyncAppointmentOption[];
    carrierOptions: SyncCarrierOption[];
    bundleTypes: BundleType[];
    legMeta: { bundleUuid: string };
  };
}

export const fetchBundleMergeSyncOptions = async (
  req: FetchBundleMergeSyncOptionsRequest
): Promise<FetchBundleMergeSyncOptionsResponse> => {
  const options = getBaseOptions();

  let response: any;
  try {
    const url = api(
      `/bundle/merge/sync-options?bundleUuid=${req.bundleUuid}&legNumber=${req.legNumber}&prepend=${req.prepend}`
    );

    response = (await request(url, options)) as FetchBundleMergeSyncOptionsResponse;
  } catch (error) {
    raiseToast(
      <SystemToast type={SystemToast.Type.ERROR} message={handleCustomError(error, 'Unable to fetch sync options')} />,
      {
        position: toast.POSITION.BOTTOM_LEFT
      }
    );

    throw error;
  }

  return response;
};

export interface MergeBundleOverrides {
  carrierUuid?: string | null;
  driverUuid?: string | null;
  truckUuid?: string | null;
}

export interface MergeBundlePayload {
  primaryBundleUuid: string;
  secondaryBundleUuid: string;
  bundleType: BundleType;
  bundleOverrides?: MergeBundleOverrides;
  prepend: boolean;
}
export interface MergeBundlesRequest {
  data: MergeBundlePayload;
}

interface MergeBundlesResponse {
  bundle: Bundle;
  error?: any;
}

export const mergeBundles = async (req: MergeBundlesRequest): Promise<MergeBundlesResponse> => {
  const options = getBaseOptions();
  options.method = 'POST';
  (options as any).body = JSON.stringify(req);

  let response: any;
  try {
    const url = api(`/bundle/merge`);

    response = (await request(url, options)) as MergeBundlesResponse;
  } catch (error) {
    raiseToast(
      <SystemToast type={SystemToast.Type.ERROR} message={handleCustomError(error, 'Unable to merge bundles')} />,
      {
        position: toast.POSITION.BOTTOM_LEFT
      }
    );

    throw error;
  }

  return response;
};

export const splitBundle = async (req: SplitBundleRequest): Promise<SplitBundleResponse> => {
  const options = getBaseOptions();
  options.method = 'POST';
  (options as any).body = JSON.stringify(req);

  let response: SplitBundleResponse;
  try {
    const url = api(`/bundle/split`);

    response = (await request(url, options)) as SplitBundleResponse;
  } catch (error) {
    raiseToast(
      <SystemToast type={SystemToast.Type.ERROR} message={handleCustomError(error, 'Unable to split bundles')} />,
      {
        position: toast.POSITION.BOTTOM_LEFT
      }
    );

    throw error;
  }

  return response;
};

export const unbundleAllLegs = async (bundleUuid: string): Promise<any> => {
  const options = getBaseOptions();
  options.method = 'PATCH';

  let response: any;
  try {
    const url = api(`/bundle/unbundle/${bundleUuid}`);

    response = (await request(url, options)) as any;
  } catch (error) {
    raiseToast(
      <SystemToast type={SystemToast.Type.ERROR} message={handleCustomError(error, 'Unable to split bundles')} />,
      {
        position: toast.POSITION.BOTTOM_LEFT
      }
    );

    throw error;
  }

  return response;
};

export const patchBundleLeg = async (req: PatchBundleLegRequest): Promise<any> => {
  const options = getBaseOptions();
  options.method = 'PATCH';
  (options as any).body = JSON.stringify({ data: [req] });

  let response: any;
  try {
    const url = api(`/bundle/legs`);

    response = await request(url, options);
  } catch (error) {
    raiseToast(
      <SystemToast
        type={SystemToast.Type.ERROR}
        message={handleCustomError(error, 'Unable to patch bundle information')}
      />,
      {
        position: toast.POSITION.BOTTOM_LEFT
      }
    );

    throw error;
  }

  return response;
};

export const addYardStorage = async (params: {
  bundleUuid: string;
  legUuid: string;
  stopUuid: string;
  nextStopInstantUuid: string | null;
  addCustomerPrepullFee: boolean;
  addCustomerStorageFee: boolean;
  addCustomerBackhaulStorageFee: boolean;
  joinBundles: boolean;
  addPrepullToMarket?: boolean;
  yardDepartment: string;
  yardDropAppointment?: {
    apptNumber: string | null;
    apptStart: string | null;
    apptEnd: string | null;
  };
  yardPickAppointment?: {
    apptNumber: string | null;
    apptStart: string | null;
    apptEnd: string | null;
  };
  nextAppointment?: {
    apptNumber: string | null;
    apptStart: string | null;
    apptEnd: string | null;
    isTerminal: boolean;
  };
}) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: params ? JSON.stringify(params) : null
  };

  let response: any;

  try {
    const url = api(`/bundle/yard-split`);

    response = await request(url, options);

    if (response.error) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message="Add storage stop succeeded, but appointments error occurred due to invalid appointment time. Please check and adjust appointment times."
        />,
        {
          position: toast.POSITION.BOTTOM_LEFT
        }
      );
    }
  } catch (error) {
    raiseToast(
      <SystemToast type={SystemToast.Type.ERROR} message={handleCustomError(error, 'Unable to add yard storage')} />,
      {
        position: toast.POSITION.BOTTOM_LEFT
      }
    );

    throw error;
  }

  return response;
};
