import moment from 'moment';

export function serializeFilters(filters: Record<string, any> | null) {
  const result: Record<string, any> = {};

  if (filters === null) {
    return result;
  }

  Object.keys(filters).forEach((groupName) => {
    if (!/\.raw$/.test(groupName)) {
      const filterKeyValues = filters[groupName];
      result[groupName] = [];

      filterKeyValues.forEach((filterKeyValue: Record<string, any>) => {
        result[groupName].push(filterKeyValue.value);
      });
    }
  });

  if (result.appointmentNonInclusive) {
    result.appointmentNonInclusive = result.appointmentNonInclusive.map((appt: Record<string, any>) => {
      let r;
      switch (appt.id) {
        case 'yesterday':
          r = {
            id: 'yesterday',
            apptType: appt.appointmentType.value,
            start: moment().subtract(1, 'day').startOf('day').add(2, 'hour').toISOString(),

            end: moment().startOf('day').add(2, 'hour').add(1, 'minute').toISOString()
          };
          break;
        case 'today':
          r = {
            id: 'today',
            apptType: appt.appointmentType.value,
            start: moment().startOf('day').add(2, 'hour').toISOString(),

            end: moment().add(1, 'day').startOf('day').add(2, 'hour').add(1, 'minute').toISOString()
          };
          break;
        case 'tomorrow':
          r = {
            id: 'tomorrow',
            apptType: appt.appointmentType.value,
            start: moment().add(1, 'day').startOf('day').add(2, 'hour').toISOString(),

            end: moment().add(2, 'day').startOf('day').add(2, 'hour').add(1, 'minute').toISOString()
          };
          break;
        default:
          r = {
            apptType: appt.appointmentType.value,
            start: appt.startValue,
            end: appt.endValue
          };
      }

      return r;
    });
  }

  return result;
}
