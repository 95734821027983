import { TerminalName } from '@client/@types/interfaces';
import { ScraperTypeName } from '@drayalliance/types';

export const terminalKeyToUIMapping: Record<TerminalName, string> = {
  apm: 'APM',
  fms: 'FMS',
  its: 'ITS',
  wbct: 'WBCT',
  yti: 'YTI',
  pct: 'PCT',
  pierA: 'Pier A',
  shippersAlameda: 'Shippers Alameda',
  shippersMiddleRoad: 'Shippers Middle Road',
  tti: 'TTI',
  lbctPierE: 'LBCT (Pier E)',
  ets: 'ETS',
  trapac: 'Trapac'
};

export const terminalKeys: TerminalName[] = Object.keys(terminalKeyToUIMapping) as TerminalName[];

export const scraperTypeKeyToUIMapping: Record<ScraperTypeName, string> = {
  availability: 'Availability',
  outgateVerification: 'Outgate',
  ingateVerification: 'Ingate',
  appointmentBooking: 'Outgate Appointment Booking',
  ingateAppointmentBooking: 'Ingate Appointment Booking',
  regionScan: 'Region Scan',
  onHire: 'On Hire'
};

export const scraperTypeKeys = Object.keys(scraperTypeKeyToUIMapping) as ScraperTypeName[];
